import React from 'react';
import './UnauthenticatedLayout.css';

const UnauthenticatedLayout = ({ children }) => {
  return (
    <div className="unauthenticated-layout">
      <header className="app-header">
          <h1 className="app-title">manatee.work : engaging business content</h1>
      </header>
      <main className="main-content">
        <div className="auth-container">
          {children}
        </div>
      </main>
    </div>
  );
};

export default UnauthenticatedLayout;
