import React, { useState, useEffect, useCallback } from 'react';
import { getNextPost, approvePost, submitFeedback, markPostAsViewed } from '../utils/api';
import useError from '../hooks/useError';
import { logInfo, logError } from '../utils/errorLogger';
import { API_URL } from '../utils/api';
import './Dashboard.css';

const Dashboard = () => {
  const [currentPost, setCurrentPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { error, handleError, clearError } = useError();

  const fetchNextPost = useCallback(async () => {
    try {
      const response = await getNextPost();
      return response.data.post || null;
    } catch (err) {
      logError('Error fetching next post', err);
      return null;
    }
  }, []);

  const loadPosts = useCallback(async () => {
    setIsLoading(true);
    clearError();
    const post1 = await fetchNextPost();
    const post2 = await fetchNextPost();
    setCurrentPost(post1);
    setNextPost(post2);
    setIsLoading(false);
    if (post1) {
      try {
        await markPostAsViewed(post1.id);
        logInfo('Marked post as viewed', { postId: post1.id });
      } catch (err) {
        logError('Error marking post as viewed', err);
      }
    }
  }, [fetchNextPost, clearError]);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  const handlePostAction = async (actionFn, actionData = null) => {
    if (!currentPost) return;

    const oldCurrentPost = currentPost;

    // Optimistic update
    if (nextPost) {
      setCurrentPost(nextPost);
      setNextPost(null);
    } else {
      setCurrentPost(null);
    }

    try {
      const response = await actionFn(oldCurrentPost.id, actionData);
      logInfo(`Post ${actionData ? 'feedback submitted' : 'approved'}`, { postId: oldCurrentPost.id });

      // Update with the actual next post from the server
      if (response.data.nextPost) {
        setCurrentPost(response.data.nextPost);
        try {
          await markPostAsViewed(response.data.nextPost.id);
          logInfo('Marked new current post as viewed', { postId: response.data.nextPost.id });
        } catch (err) {
          logError('Error marking new current post as viewed', err);
        }
      } else if (!nextPost) {
        // If we don't have a next post, try to fetch one
        const newPost = await fetchNextPost();
        setCurrentPost(newPost);
        if (newPost) {
          try {
            await markPostAsViewed(newPost.id);
            logInfo('Marked fetched post as viewed', { postId: newPost.id });
          } catch (err) {
            logError('Error marking fetched post as viewed', err);
          }
        }
      }
    } catch (err) {
      handleError(err);
      // Revert optimistic update if there's an error
      setCurrentPost(oldCurrentPost);
    }

    // Always try to fetch a new next post
    const newNextPost = await fetchNextPost();
    setNextPost(newNextPost);
  };

  const handleApprove = () => handlePostAction(approvePost);

  const handleFeedback = () => {
    handlePostAction(submitFeedback, feedbackText);
    setFeedbackText('');
  };

  if (isLoading) {
    return <div className="dashboard loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="dashboard error-state">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={loadPosts}>Try Again</button>
      </div>
    );
  }

  if (!currentPost) {
    return (
      <div className="dashboard no-posts-state">
        <h2>No Posts Available</h2>
        <p>There are no posts ready for review at the moment. Check back later!</p>
        <button onClick={loadPosts}>Check for New Posts</button>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <h2>Review Your Next Post</h2>
      <div className="post">
        <img src={`${API_URL.replace(/\/api$/, '')}${currentPost.imageUrl}`} alt="Generated content" className="post-image" />
        <p className="post-content">{currentPost.content}</p>
      </div>

      <div className="actions">
        <button onClick={handleApprove} className="approve-button">Approve</button>
      </div>

      <div className="feedback-section">
        <h3>Provide Feedback</h3>
        <textarea
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          placeholder="Enter your feedback here"
          className="feedback-textarea"
        />
        <div className="feedback-actions">
          <button
            onClick={handleFeedback}
            disabled={!feedbackText.trim()}
            className="submit-feedback-button"
          >
            Submit Feedback
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
