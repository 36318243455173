import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import BusinessProfile from './components/BusinessProfile';
import Welcome from './components/Welcome';

export const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  WELCOME: '/welcome',
  HOME: '/',
};

export const getRoutes = (AuthenticatedRoute, UnauthenticatedRoute) => [
  {
    path: ROUTES.LOGIN,
    element: <UnauthenticatedRoute><Login /></UnauthenticatedRoute>,
  },
  {
    path: ROUTES.SIGNUP,
    element: <UnauthenticatedRoute><SignUp /></UnauthenticatedRoute>,
  },
  {
    path: ROUTES.DASHBOARD,
    element: <AuthenticatedRoute><Dashboard /></AuthenticatedRoute>,
  },
  {
    path: ROUTES.PROFILE,
    element: <AuthenticatedRoute><BusinessProfile /></AuthenticatedRoute>,
  },
  {
    path: ROUTES.WELCOME,
    element: <AuthenticatedRoute><Welcome /></AuthenticatedRoute>,
  },
  {
    path: ROUTES.HOME,
    element: <Navigate to={ROUTES.LOGIN} replace />,
  },
];
