// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthenticated-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
}

.app-header {
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--color-border);
}

.app-title {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin: 0;
  padding: 0;
}

.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-md);
}

.auth-container {
  width: 100%;
  max-width: 400px;
}

@media (max-width: 768px) {
  .app-header {
    padding: var(--spacing-xs) 0;
  }

  .app-title {
    font-size: var(--font-size-lg);
  }

  .main-content {
    padding-top: var(--spacing-sm);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UnauthenticatedLayout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,yCAAyC;AAC3C;;AAEA;EACE,4BAA4B;EAC5B,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,2BAA2B;EAC3B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".unauthenticated-layout {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  background-color: var(--color-background);\n}\n\n.app-header {\n  padding: var(--spacing-sm) 0;\n  border-bottom: 1px solid var(--color-border);\n}\n\n.app-title {\n  text-align: center;\n  font-size: var(--font-size-xl);\n  color: var(--color-primary);\n  margin: 0;\n  padding: 0;\n}\n\n.main-content {\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  padding-top: var(--spacing-md);\n}\n\n.auth-container {\n  width: 100%;\n  max-width: 400px;\n}\n\n@media (max-width: 768px) {\n  .app-header {\n    padding: var(--spacing-xs) 0;\n  }\n\n  .app-title {\n    font-size: var(--font-size-lg);\n  }\n\n  .main-content {\n    padding-top: var(--spacing-sm);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
