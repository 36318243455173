import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './AuthenticatedLayout.css';

const AuthenticatedLayout = ({ children }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isActivePage = (path) => location.pathname === path;

  return (
    <div className="authenticated-layout">
      <nav className="main-nav">
        <div className="container">
          <div className="nav-content">
            <div className="nav-links">
              {!isActivePage('/dashboard') && (
                <Link
                  to="/dashboard"
                  className={`nav-button ${isActivePage('/dashboard') ? 'active' : ''}`}
                >
                  Dashboard
                </Link>
              )}
              {!isActivePage('/profile') && (
                <Link
                  to="/profile"
                  className={`nav-button ${isActivePage('/profile') ? 'active' : ''}`}
                >
                  Business Profile
                </Link>
              )}
            </div>
            <button onClick={handleLogout} className="logout-button">Logout</button>
          </div>
        </div>
      </nav>
      <main className="main-content container">
        {children}
      </main>
    </div>
  );
};

export default AuthenticatedLayout;
