import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import api from '../utils/api';
import useError from '../hooks/useError';
import { logInfo } from '../utils/errorLogger';
import './BusinessProfile.css';

const BusinessProfile = () => {
  const { user, profileCompleted, setProfileCompleted } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    businessName: '',
    businessType: '',
    targetAudience: '',
    brandVoice: '',
    phoneNumber: '',
    businessPreferences: ''
  });
  const { error, handleError, clearError } = useError();
  const [success, setSuccess] = useState('');
  const [isNewProfile, setIsNewProfile] = useState(!profileCompleted);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProfile = useCallback(async () => {
    if (!user) return null;
    try {
      clearError();
      const response = await api.get('/profile');
      if (response.data && Object.keys(response.data).length > 0) {
        setProfile(response.data);
        setIsNewProfile(false);
        setProfileCompleted(true);
        logInfo('Profile fetched successfully', { userId: user.id });
        return response.data;
      } else {
        setIsNewProfile(true);
        setProfileCompleted(false);
        logInfo('No existing profile found', { userId: user.id });
        return null;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsNewProfile(true);
        setProfileCompleted(false);
        logInfo('No profile found, new user', { userId: user.id });
        return null;
      } else {
        handleError(error);
        return null;
      }
    } finally {
      setIsLoading(false);
    }
  }, [user, setProfileCompleted, clearError, handleError]);

  useEffect(() => {
    if (user && isLoading) {
      fetchProfile();
    }
  }, [user, fetchProfile, isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearError();
    setSuccess('');
    try {
      const method = isNewProfile ? 'post' : 'put';
      const response = await api[method]('/profile', profile);
      setSuccess('Profile updated successfully');
      setIsNewProfile(false);
      setProfile(response.data);
      setProfileCompleted(true);
      logInfo(`Profile ${isNewProfile ? 'created' : 'updated'} successfully`, { userId: user.id });
      navigate('/dashboard');
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  if (isLoading) {
    return <div className="business-profile-container loading">Loading...</div>;
  }

  return (
    <div className="business-profile-container">
      <div className="business-profile-card">
        <h1 className="business-profile-title">{isNewProfile ? 'Create Your Business Profile' : 'Update Your Business Profile'}</h1>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        <form onSubmit={handleSubmit} className="business-profile-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="businessName">Business Name:</label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                value={profile.businessName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="businessType">Business Type:</label>
              <input
                type="text"
                id="businessType"
                name="businessType"
                value={profile.businessType}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="targetAudience">Target Audience:</label>
              <input
                type="text"
                id="targetAudience"
                name="targetAudience"
                value={profile.targetAudience}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="brandVoice">Brand Voice:</label>
              <input
                type="text"
                id="brandVoice"
                name="brandVoice"
                value={profile.brandVoice}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={profile.phoneNumber}
                onChange={handleChange}
                placeholder="+33XXXXXXXXX"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width">
              <label htmlFor="businessPreferences">Business Preferences:</label>
              <textarea
                id="businessPreferences"
                name="businessPreferences"
                value={profile.businessPreferences}
                onChange={handleChange}
                rows="4"
              />
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="update-profile-button">
              {isNewProfile ? 'Create Profile' : 'Update Profile'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessProfile;
