// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: var(--spacing-md);
}

.auth-card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 400px;
}

.auth-title {
  font-size: var(--font-size-xxl);
  text-align: center;
  margin-bottom: var(--spacing-xs);
}

.auth-subtitle {
  font-size: var(--font-size-lg);
  text-align: center;
  color: var(--color-text-secondary);
  margin-bottom: var(--spacing-lg);
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form .form-group {
  margin-bottom: var(--spacing-md);
}

.auth-form label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
}

.auth-form input {
  width: 100%;
}

.auth-button {
  margin-top: var(--spacing-md);
}

.auth-link {
  text-align: center;
  margin-top: var(--spacing-lg);
  font-size: var(--font-size-sm);
}

@media (max-width: 480px) {
  .auth-card {
    padding: var(--spacing-lg);
  }

  .auth-title {
    font-size: var(--font-size-xl);
  }

  .auth-subtitle {
    font-size: var(--font-size-md);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,yCAAyC;EACzC,sCAAsC;EACtC,4BAA4B;EAC5B,0BAA0B;EAC1B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,kCAAkC;EAClC,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".auth-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  padding: var(--spacing-md);\n}\n\n.auth-card {\n  background-color: var(--color-background);\n  border-radius: var(--border-radius-lg);\n  box-shadow: var(--shadow-lg);\n  padding: var(--spacing-xl);\n  width: 100%;\n  max-width: 400px;\n}\n\n.auth-title {\n  font-size: var(--font-size-xxl);\n  text-align: center;\n  margin-bottom: var(--spacing-xs);\n}\n\n.auth-subtitle {\n  font-size: var(--font-size-lg);\n  text-align: center;\n  color: var(--color-text-secondary);\n  margin-bottom: var(--spacing-lg);\n}\n\n.auth-form {\n  display: flex;\n  flex-direction: column;\n}\n\n.auth-form .form-group {\n  margin-bottom: var(--spacing-md);\n}\n\n.auth-form label {\n  display: block;\n  margin-bottom: var(--spacing-xs);\n  font-weight: 500;\n}\n\n.auth-form input {\n  width: 100%;\n}\n\n.auth-button {\n  margin-top: var(--spacing-md);\n}\n\n.auth-link {\n  text-align: center;\n  margin-top: var(--spacing-lg);\n  font-size: var(--font-size-sm);\n}\n\n@media (max-width: 480px) {\n  .auth-card {\n    padding: var(--spacing-lg);\n  }\n\n  .auth-title {\n    font-size: var(--font-size-xl);\n  }\n\n  .auth-subtitle {\n    font-size: var(--font-size-md);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
