import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { setToken, removeToken, getToken, isTokenExpired, refreshToken } from '../utils/auth';
import { logError, logInfo } from '../utils/errorLogger';
import api from '../utils/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profileCompleted, setProfileCompleted] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      const token = getToken();
      if (token) {
        if (isTokenExpired(token)) {
          try {
            const newToken = await refreshToken();
            setToken(newToken);
            const userData = JSON.parse(localStorage.getItem('user'));
            if (userData) {
              setUser(userData);
              setProfileCompleted(userData.profileCompleted);
              api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
              logInfo('User session restored with refreshed token');
            } else {
              removeToken();
              logError(new Error('User data not found in local storage'));
            }
          } catch (error) {
            removeToken();
            logError(error, { message: 'Failed to refresh token' });
          }
        } else {
          const userData = JSON.parse(localStorage.getItem('user'));
          if (userData) {
            setUser(userData);
            setProfileCompleted(userData.profileCompleted);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            logInfo('User session restored');
          } else {
            removeToken();
            logError(new Error('User data not found in local storage'));
          }
        }
      }
    };

    initAuth();
  }, []);

  const login = useCallback((userData) => {
    const userToStore = {
      id: userData.id,
      email: userData.email,
      token: userData.token,
      profileCompleted: userData.profileCompleted || false
    };
    setUser(userToStore);
    setProfileCompleted(userToStore.profileCompleted);
    setToken(userData.token);
    localStorage.setItem('user', JSON.stringify(userToStore));
    api.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
    logInfo('User logged in', { userId: userData.id });
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setProfileCompleted(false);
    removeToken();
    localStorage.removeItem('user');
    delete api.defaults.headers.common['Authorization'];
    logInfo('User logged out');
  }, []);

  const updateProfileStatus = useCallback((status) => {
    setProfileCompleted(status);
    if (user) {
      const updatedUser = { ...user, profileCompleted: status };
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  }, [user]);

  const contextValue = {
    user,
    login,
    logout,
    profileCompleted,
    setProfileCompleted: updateProfileStatus
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
