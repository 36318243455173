const LOG_LEVELS = {
  ERROR: 'error',
  INFO: 'info',
};

class ErrorLogger {
  static log(message, info = {}, level = LOG_LEVELS.INFO) {
    const logData = {
      level,
      message,
      ...info,
      url: window.location.href,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
    };

    switch (level) {
      case LOG_LEVELS.ERROR:
        console.error('Client Error:', logData);
        break;
      case LOG_LEVELS.INFO:
        console.info('Client Info:', logData);
        break;
      default:
        console.log('Client Log:', logData);
    }

    // Here you would typically send the log data to your server or a logging service
  }

  static error(error, additionalInfo = {}) {
    this.log(error.message, {
      ...additionalInfo,
      stack: error.stack,
      name: error.name,
    }, LOG_LEVELS.ERROR);
  }

  static info(message, info = {}) {
    this.log(message, info, LOG_LEVELS.INFO);
  }

  static init() {
    window.addEventListener('error', (event) => {
      this.error(event.error, {
        type: 'Uncaught Error',
        line: event.lineno,
        column: event.colno
      });
    });

    window.addEventListener('unhandledrejection', (event) => {
      this.error(event.reason, {
        type: 'Unhandled Promise Rejection'
      });
    });
  }
}

export const logError = ErrorLogger.error.bind(ErrorLogger);
export const logInfo = ErrorLogger.info.bind(ErrorLogger);

export default ErrorLogger;
