import { useState, useCallback } from 'react';
import { logError } from '../utils/errorLogger';

const useError = () => {
  const [error, setError] = useState(null);
  const [retryAfter, setRetryAfter] = useState(0);

  const handleError = useCallback((error) => {
    if (error.response && error.response.status === 429) {
      const retryAfterSeconds = parseInt(error.response.headers['retry-after'] || '60', 10);
      setRetryAfter(retryAfterSeconds);
      setError(`Too many requests. Please try again in ${retryAfterSeconds} seconds.`);

      setTimeout(() => {
        setRetryAfter(0);
      }, retryAfterSeconds * 1000);
    } else {
      setError(error.response?.data?.error || error.message || 'An unexpected error occurred.');
    }

    // Log the error using errorLogger
    logError(error, {
      component: 'useError hook',
      additionalInfo: error.response?.data
    });
  }, []);

  const clearError = useCallback(() => {
    setError(null);
    setRetryAfter(0);
  }, []);

  return { error, retryAfter, handleError, clearError };
};

export default useError;
