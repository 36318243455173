import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import AuthenticatedLayout from './components/AuthenticatedLayout';
import UnauthenticatedLayout from './components/UnauthenticatedLayout';
import ErrorBoundary from './components/ErrorBoundary';
import Welcome from './components/Welcome';
import { getRoutes, ROUTES } from './routes';
import { logError } from './utils/errorLogger';

const AuthenticatedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? <AuthenticatedLayout>{children}</AuthenticatedLayout> : <Navigate to={ROUTES.LOGIN} replace />;
};

const UnauthenticatedRoute = ({ children }) => {
  const { user } = useAuth();
  return !user ? <UnauthenticatedLayout>{children}</UnauthenticatedLayout> : <Navigate to={ROUTES.DASHBOARD} replace />;
};

const AppContent = () => {
  const routes = getRoutes(AuthenticatedRoute, UnauthenticatedRoute);

  return (
    <ErrorBoundary onError={logError}>
      <Router>
        <div style={{ minHeight: '100vh' }}>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
              <Route
                path={ROUTES.WELCOME}
                element={<AuthenticatedRoute><Welcome /></AuthenticatedRoute>}
              />
            </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
};

const App = () => (
  <AuthProvider>
    <AppContent />
  </AuthProvider>
);

export default App;
