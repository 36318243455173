// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-profile-container {
  display: flex;
  justify-content: center;
  padding: var(--spacing-lg);
}

.business-profile-card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 600px;
}

.business-profile-title {
  font-size: var(--font-size-xxl);
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.business-profile-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-xs);
  font-weight: 500;
}

.form-group input {
  width: 100%;
}

.form-actions {
  margin-top: var(--spacing-lg);
}

.update-profile-button {
  width: 100%;
}

@media (max-width: 768px) {
  .business-profile-container {
    padding: var(--spacing-md);
  }

  .business-profile-card {
    padding: var(--spacing-lg);
  }

  .business-profile-title {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 480px) {
  .form-group input {
    font-size: 16px; /* Prevents auto-zoom on iOS */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BusinessProfile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,yCAAyC;EACzC,sCAAsC;EACtC,4BAA4B;EAC5B,0BAA0B;EAC1B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,eAAe,EAAE,8BAA8B;EACjD;AACF","sourcesContent":[".business-profile-container {\n  display: flex;\n  justify-content: center;\n  padding: var(--spacing-lg);\n}\n\n.business-profile-card {\n  background-color: var(--color-background);\n  border-radius: var(--border-radius-lg);\n  box-shadow: var(--shadow-md);\n  padding: var(--spacing-xl);\n  width: 100%;\n  max-width: 600px;\n}\n\n.business-profile-title {\n  font-size: var(--font-size-xxl);\n  text-align: center;\n  margin-bottom: var(--spacing-lg);\n}\n\n.business-profile-form {\n  display: flex;\n  flex-direction: column;\n}\n\n.form-group {\n  margin-bottom: var(--spacing-md);\n}\n\n.form-group label {\n  display: block;\n  margin-bottom: var(--spacing-xs);\n  font-weight: 500;\n}\n\n.form-group input {\n  width: 100%;\n}\n\n.form-actions {\n  margin-top: var(--spacing-lg);\n}\n\n.update-profile-button {\n  width: 100%;\n}\n\n@media (max-width: 768px) {\n  .business-profile-container {\n    padding: var(--spacing-md);\n  }\n\n  .business-profile-card {\n    padding: var(--spacing-lg);\n  }\n\n  .business-profile-title {\n    font-size: var(--font-size-xl);\n  }\n}\n\n@media (max-width: 480px) {\n  .form-group input {\n    font-size: 16px; /* Prevents auto-zoom on iOS */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
