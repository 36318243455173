import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Welcome = () => {
  const { user } = useAuth();

  return (
    <div className="welcome-container">
      <div className="welcome-card">
        <h1 className="welcome-title">Welcome to Manatee!</h1>
        <p className="welcome-message">
          Hello {user.email}, we're excited to have you on board. Let's get started by setting up your business profile.
        </p>
        <div className="welcome-steps">
          <h2>Next Steps:</h2>
          <ol>
            <li>Create your business profile</li>
            <li>Generate your first social media post</li>
            <li>Review and approve your content</li>
          </ol>
        </div>
        <Link to="/profile" className="start-button">
          Create Business Profile
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
